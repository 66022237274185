<template>
  <main>
    <CForm>
      <CCard>
        <CAlert
            id="ChooseNotice"
            v-if="(ChooseSlugs.length > 0)"
            color="info"
            :fade="false"
        >
          <span class="text">
            {{ $t('Global.SelectedLabel') }}
            <span class="font-weight-bold">{{ ChooseSlugs.length }}</span>
            {{ $t('Global.ItemUnit') }}
          </span>
          <div class="ButtonGroup">
            <CButton color="light" size="sm" class="mr-2" @click="ChooseSlugs = [];CheckAllValue = false">{{ $t('Global.Cancel') }}</CButton>
            <CButton v-if="$store.state.user.permission.Permission.Post.Items.includes('W')" color="info" size="sm" class="mr-2" @click="BulkEditModel = true">{{ $t('Global.BulkEdit') }}</CButton>
            <CButton v-if="$store.state.user.permission.Permission.Post.Items.includes('D')" color="danger" size="sm" @click="AlertModel = true">{{ $t('Global.Delete') }}</CButton>
          </div>
        </CAlert>
        <CCardHeader>
          <CRow class="d-flex align-items-center">
            <CCol col="6">
              <h5 class="mb-0">{{ $t('Navigation.System/ThemeBuildingPage') }}</h5>
            </CCol>
            <CCol col="6" class="d-flex justify-content-end">
              <CButton color="success" variant="outline" shape="pill" class="mr-2" type="button" @click="AddThemeBuildPageModel = true">
                <CIcon size="sm" class="mr-1" name="cil-plus"/>{{ $t('Navigation.Content/ThemeBuildingAdd') }}
              </CButton>
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardHeader class="w-100 overflow-auto px-2">
          <CRow>
            <CCol>
              <CButton color="white" shape="pill" class="mr-2" @click="FilterModel = true;ShowFilter = 'All'">
                <CIcon name="cil-filter"/>
              </CButton>
              <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('Slug')">
                {{ $t('Post/List.Slug') }}: {{ SearchFilter.slug.join(',') || '--' }}
                <CIcon size="sm" class="ml-1" name="cil-pencil"/>
              </CButton>
              <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('Name')">
                {{ $t('Post/List.Name') }}: {{ SearchFilter.name || '--' }}
                <CIcon size="sm" class="ml-1" name="cil-pencil"/>
              </CButton>
              <CButton size="sm" color="danger" shape="pill" class="mr-2" @click="ResetFilter()">
                <CIcon size="sm" name="cil-x"/>
              </CButton>
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody class="p-0">
          <CDataTable
              id="PostList"
              :items="List"
              :fields="Field"
              :loading="Loading"
              :noItemsView="noItemsView"
              responsive
              hover
          >
            <template #Checkbox-header>
              <label class="mb-0">
                <input type="checkbox" id="CheckAll" v-model="CheckAllValue" @change="CheckAll()">
              </label>
            </template>
            <template #Checkbox="{item}">
              <td class="text-center">
                <label class="mb-0">
                  <input type="checkbox" :id="'Checkbox-Post-' + item.Slug" :value="item.Slug" v-model="ChooseSlugs">
                </label>
              </td>
            </template>
            <template #Name="{item}">
              <td>
                <CIcon class="mr-1" :name="ThemeBuildPageCountry(item.Slug)" />{{ item.Name }}
              </td>
            </template>
            <template #Status="{item}">
              <td>
                <CBadge :color="(item.Status === 1 ? 'success' : 'danger')">
                  {{ $t('Product.Status.' + item.Status) }}
                </CBadge>
              </td>
            </template>
            <template #Path="{item}">
              <td>
                <a :href="'https://' + ThemeBuildPageURL(item.Slug)" class="text-info" target="_blank">
                  https://{{ ThemeBuildPageURL(item.Slug) }}
                </a>
              </td>
            </template>
            <template #Action="{item}">
              <td>
                <CButton v-if="$store.state.user.permission.Permission.Post.Items.includes('W')" color="info" size="sm" class="mr-1" @click="OpenWindow(`/content/builder/${item.Slug}`)">
                  <CIcon name="cil-pencil" class="c-icon-custom-size mr-1"/> {{ $t('Post/List.Edit') }}
                </CButton>
                <CButton color="secondary" size="sm" class="mr-1" v-c-tooltip="$t('Global.Duplicate')" @click="Duplicate(item.Slug)">
                  <CIcon name="cil-copy" class="c-icon-custom-size mr-1" />
                </CButton>
              </td>
            </template>
          </CDataTable>
        </CCardBody>
        <CCardFooter>
          <CPagination
              :activePage.sync="Pagination.Page"
              :pages.sync="Pagination.TotalPages"
              @update:activePage="ChangePage()"
          />
        </CCardFooter>
      </CCard>
    </CForm>
    <CModal id="BulkEditModel" class="ActionModel" :show.sync="BulkEditModel" :centered="true"
            :closeOnBackdrop="!Submit">
      <CRow form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Post/Detail.Status') }}
        </CCol>
        <CCol sm="9">
          <CSwitch color="success" :checked.sync="BulkEditData.Status"/>
        </CCol>
      </CRow>
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Global.BulkEdit') }}</h5>
          <CButtonClose @click="BulkEditModel = false"/>
        </header>
      </template>
      <template #footer-wrapper>
        <CElementCover
          v-if="(Submit === true)"
          :boundaries="[{ sides: ['center', 'center'], query: '#BulkEditSubmit' }]"
          :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="BulkEditModel = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="BulkEditSubmit" @click="BulkEdit()" color="success">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
    <CModal id="AlertModel" class="ActionModel" :show.sync="AlertModel" :centered="true" :closeOnBackdrop="!Submit">
      <CAlert color="danger">
        <CIcon name="cil-bell" /> {{ $t('Global.ConfirmDelete') }}
      </CAlert>
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Global.Delete') }}</h5>
          <CButtonClose @click="AlertModel = false" />
        </header>
      </template>
      <template #footer-wrapper>
        <CElementCover
            v-if="(Submit === true)"
            :boundaries="[{ sides: ['center', 'center'], query: '#DeleteSubmit' }]"
            :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="AlertModel = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="DeleteSubmit" @click="Delete()" color="danger">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
    <CModal :show.sync="FilterModel" :centered="true" :closeOnBackdrop="!Submit" @update:show="ChangePage()">
      <CInput v-if="ShowFilter === 'All' || ShowFilter === 'Name'" :label="$t('Post/List.Name')" v-model="SearchFilter.name" horizontal/>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'Slug'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Post/List.Slug') }}
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SearchFilter.slug"
            v-model="SearchFilter.slug"
            :optionHeight="24"
            :tag-placeholder="$t('Global.EnterToAdd')"
            :placeholder="''"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :options="SearchFilter.slug"
            :multiple="true"
            :taggable="true"
            @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
      </CRow>
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Global.Filter') }}</h5>
          <CButtonClose @click="FilterModel = false"/>
        </header>
      </template>
      <template #footer-wrapper>
        <CElementCover
          v-if="(Submit === true)"
          :boundaries="[{ sides: ['center', 'center'], query: '#FilterModelSubmit' }]"
          :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="FilterModel = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="FilterModelSubmit" @click="ChangePage()" color="success">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
    <CModal :show.sync="AddThemeBuildPageModel" :centered="true" :closeOnBackdrop="!Submit">
      <CInput label="名稱" v-model="Data.Name" horizontal />
      <CInput label="代稱" v-model="Data.Slug" horizontal />
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">新贈主頁</h5>
          <CButtonClose @click="AddThemeBuildPageModel = false"/>
        </header>
      </template>
      <template #footer-wrapper>
        <CElementCover
          v-if="(Submit === true)"
          :boundaries="[{ sides: ['center', 'center'], query: '#FilterModelSubmit' }]"
          :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="AddThemeBuildPageModel = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="AddThemeBuildPageModelSubmit" @click="AddThemeBuildPage()" color="success">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
    <component :is="Component" :Toggle.sync="ToggleModal" @Success="GetList()" />
  </main>
</template>

<route>
{
  "meta": {
    "label": "頁面列表"
  }
}
</route>

<script>
export default {
  name: 'ListPost',
  layout: 'manage',
  components: {
    Multiselect: () => import('@/plugins/mutiselect'),
  },
  data () {
    return {
      List: [],
      CategoryList: [],
      SelectCategories: [],
      AlertModel: false,
      BulkEditModel: false,
      AddThemeBuildPageModel: false,
      BulkEditData: {
        Status: true
      },
      Data: {
        Name: '',
        Slug: '',
        Taxonomy: 'ThemesBuild',
        SEO: {},
        Variable: {
          Template: '[]'
        }
      },
      ChooseSlugs: [],
      CheckAllValue: false,
      Pagination: {
        Page: 1,
        PerPage: 10,
        TotalItems: 0,
        TotalPages: 0
      },
      Order: 'nto',
      Loading: false,
      Submit: false,
      noItemsView: {
        noResults: this.$t('Post/List.NoResults'),
        noItems: this.$t('Post/List.NoItems')
      },
      SearchFilter: {
        name: '',
        slug: []
      },
      ShowFilter: 'All',
      FilterModel: false,
      Component: null,
      ToggleModal: false
    }
  },
  computed: {
    Field () {
      return [
        { key: 'Checkbox', label: '' },
        { key: 'Name', label: this.$t('Post/List.Name') },
        { key: 'Slug', label: this.$t('Post/List.Slug') },
        { key: 'Path', label: this.$t('Post/List.Path') },
        { key: 'Status', label: this.$t('Post/List.Status') },
        { key: 'Action', label: '' }
      ]
    }
  },
  mounted() {
    this.$Progress.start()
    this.Init().then(() => {
      this.$Progress.finish()
    }).catch((err) => {
      this.$Progress.fail()
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: (err.type ? err.type : 'error')
      })
    })
  },
  methods: {
    Init() {
      return Promise.all([
        this.GetList(),
      ]).catch((err) => {
        throw err
      })
    },
    GetList() {
      let RequestQuery = {
        taxonomy: 'ThemesBuild',
        page: this.$route.query.page || this.Pagination.Page,
        perPage: this.$route.query.perPage || this.Pagination.PerPage,
        order: this.$route.query.order || this.Order
      }
      Object.keys(this.SearchFilter).forEach((param) => {
        RequestQuery[param] = this.$route.query[param]
      })
      this.List = []
      this.Loading = true
      this.FilterModel = false
      return this.$store.dispatch('InnerRequest', {
        url: '/content/list',
        method: 'get',
        params: RequestQuery
      }).then(({data}) => {
        this.Loading = false
        this.List = data.list
        this.Pagination = data.pagination
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    ChangePage() {
      const query = {
        page: this.Pagination.Page,
        perPage: this.Pagination.PerPage,
        order: this.Order,
      }
      if (this.SearchFilter.name) query.name = this.SearchFilter.name
      if (this.SearchFilter.slug.length > 0) query.slug = this.SearchFilter.slug.join(',')
      this.$router.replace({
        path: '/content/builder',
        query
      }).then(() => {
        this.Init()
      })
    },
    Delete() {
      this.Submit = true
      return this.$store.dispatch('InnerRequest', {
        url: '/content/delete',
        method: 'post',
        data: {
          taxonomy: 'ThemesBuild',
          slug: this.ChooseSlugs
        }
      }).then(() => {
        this.Init()
        this.ResetState()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Post/DeleteSuccess'),
          type: 'success'
        })
      }).catch((err) => {
        this.ResetState()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Post/DeleteFail') + err.msg,
          type: 'error'
        })
      })
    },
    CheckAll() {
      if (this.CheckAllValue === true) {
        this.ChooseSlugs = this.List.map(data => {
          return data.Slug
        })
      } else {
        this.CheckAllValue = false
        this.ChooseSlugs = []
      }
    },
    ResetState() {
      this.Submit = false
      this.AlertModel = false
      this.ChooseSlugs = []
    },
    Duplicate(slug) {
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/content/duplicate',
        method: 'get',
        params: {
          taxonomy: 'ThemesBuild',
          slug
        }
      }).then(() => {
        this.GetList()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Post/SuccessCopy'),
          type: 'success'
        })
      }).catch((error) => {
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Post/FailCopy') + error.msg,
          type: 'error'
        })
      })
    },
    AddSearchFilter(newTag, id) {
      id = id.replace('SearchFilter.', '')
      this.SearchFilter[id].push(newTag.trim())
    },
    BulkEdit() {
      this.Submit = true
      const UpdateData = {}
      Object.keys(this.BulkEditData).forEach((index) => {
        //驗證是否為物件不為陣列
        switch (typeof this.BulkEditData[index]) {
          case 'object':
            if (!Array.isArray(this.BulkEditData[index])) {
              Object.keys(this.BulkEditData[index]).forEach((subIndex) => {
                if (this.BulkEditData[index][subIndex] !== '') {
                  UpdateData[index + '.' + subIndex] = this.BulkEditData[index][subIndex]
                }
              })
            } else {
              if (this.BulkEditData[index].length > 0) {
                UpdateData[index] = this.BulkEditData[index]
              }
            }
            break
          default:
            if (this.BulkEditData[index] !== '') {
              UpdateData[index] = this.BulkEditData[index]
            }
            break
        }
      })
      return this.$store.dispatch('InnerRequest', {
        url: '/service/action',
        method: 'post',
        data: {
          URL: '/content/article/edit',
          Data: {
            Taxonomy: 'ThemesBuild',
            Slug: this.ChooseSlugs,
            UpdateData
          },
        }
      }).then(() => {
        this.Init()
        this.Submit = false
        this.BulkEditModel = false
        this.BulkEditData = {
          Status: true
        }
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Post/BulkEditSuccess'),
          type: 'success'
        })
      }).catch((err) => {
        this.Init()
        this.BulkEditModel = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/ErrorMessage') + err.msg,
          type: 'error'
        })
      })
    },
    OpenFilter(type) {
      this.FilterModel = true
      this.ShowFilter = type
    },
    ResetFilter() {
      this.Submit = false
      this.AlertModel = false
      this.BulkEditModel = false
      this.BulkEditData = {
        Status: true,
      }
      this.SearchFilter = {
        name: '',
        slug: []
      }
      this.ChooseSlugs = []
      this.$router.replace({
        path: '/content/builder'
      }).then(() => {
        this.Init()
      })
    },
    Export(type = 'GoogleSheets') {
      switch (type) {
        case 'GoogleSheets':
          this.Component = () => import('@/components/ExportToGoogleSheet')
          break
        default:
          return
      }
      setTimeout(() => {
        this.ToggleModal = true
      }, 500)
    },
    OpenWindow(_link) {
      return window.open(_link, '_blank');
    },
    AddThemeBuildPage() {
      this.Submit = true
      return this.$store.dispatch('InnerRequest', {
        url: '/service/action',
        method: 'post',
        data: {
          URL: '/content/article/add',
          Data: this.Data
        }
      }).then(({ data }) => {
        this.Submit = false
        this.$router.push('/content/builder/' + data.Data.Slug)
      })
    },
    ThemeBuildPageCountry(slug = '') {
      if (slug.includes('zh-tw')) {
        return 'cif-tw'
      } else if (slug.includes('en-us')) {
        return 'cif-us'
      } else if (slug.includes('vi-vn')) {
        return 'cif-vn'
      } else if (slug.includes('ja-jp')) {
        return 'cif-jp'
      } else if (slug.includes('zh-cn')) {
        return 'cif-cn'
      } else if (slug.includes('ko-kr')) {
        return 'cif-kr'
      }
      return 'cil-star'
    },
    ThemeBuildPageURL(slug = '') {
      if (slug.includes('zh-tw')) {
        slug = slug.replace('_zh-tw', '')
      } else if (slug.includes('en-us')) {
        slug = slug.replace('_en-us', '')
      } else if (slug.includes('vi-vn')) {
        slug = slug.replace('_vi-vn', '')
      } else if (slug.includes('ja-jp')) {
        slug = slug.replace('_ja-jp', '')
      } else if (slug.includes('zh-cn')) {
        slug = slug.replace('_zh-cn', '')
      } else if (slug.includes('ko-kr')) {
        slug = slug.replace('_ko-kr', '')
      }
      let url = this.$store.state.project.Domain + '/'
      if (slug.includes('home')) {
        return url
      }
      return url + slug
    }
  }
}
</script>

<style>
#ChooseNotice {
  position: absolute;
  z-index: 10;
  width: 100%;
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
  padding: 0.5rem 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#PostList thead th:first-child {
  text-align: center;
}
#PostList tbody td:first-child {
  vertical-align: middle;
}
</style>
